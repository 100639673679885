:root {
    --padding: 2%; /* Must be a percentage value if you want IE10 compatability */
    --margin: 1%; /* Must be a percentage value if you want IE10 compatability */
    --vertical-padding: 2em; /* must NOT be a percentage value */
    --vertical-margin: 2em; /* must NOT be a percentage value */
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 800px;
    --container-max-width: 1100px;
    --fixed-header-height: 80px;
}

@import "/fontmodules/Roboto.css";
@import "/fontmodules/AbhayaLibre.css";
@import "/cssmodules/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/cssmodules/basic_styling.css";
@import "/cssmodules/flex_grid.v1.1.css";
@import "/cssmodules/slick.css";
@import "/cssmodules/slick-theme.css";
@import "/cssmodules/menu.v1.1.css";
@import "/cssmodules/font-awesome.css";
@import "/cssmodules/magnific-popup.css";
/*@import "/cssmodules/animate.css";*/



body {
    background: #2B5A8A;
    font-family: 'AbhayaLibre', serif;
    /* font-weight's att välja mellan är 100,300,400,500,700,900 */
}


header {
 background: #2B5A8A;
    z-index: 100;
    position:fixed;
    top: 0;
    width: 100%;
}


.hemisdaochdesignav  {
    background:#2B5A8A;
    padding: 8px 20px 8px 8px;
    font-size: 0.7rem;
    color:#FFFFFF;
}

.hemisdaochdesignav p, .hemisdaochdesignav a {
    font-family: "Roboto", sans-serif;
    color:#FFFFFF;
    font-size: 0.7rem;
    padding:0px;
    margin:0px;
}



/*----------------------------------- Logotype --------------------------------------*/

.logo a {
    padding: 15px 0px 10px 0px;
}/*Add width:100% för att ge egen "rad"*/


.logo img {
    height: 8vh;
    max-height: 8vh;
    width: auto;
    fill:#336699;
}






/*-------------------------------------------------------------- Bildspel ------------------------------------------------------------------------*/

.slick-initialized .slick-slide{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}


.slick-slider {
    margin-top: 40px;
    position: fixed;
    width:100%; 
    height:730px;
    top:0;
    z-index: -10; /* z-index - sätter vilken "nivå" bildspelet / "vad man vill" ska hamna på */
}

.slick-slide {
    height: 730px;
    width:100%;
    background-size: cover;
    background-position: top center ;
}


.bildspel_toning {
    background:rgba(64, 100, 113, 0.4);    
    margin-top: 0px;
    position: relative;
    width:100%; 
    height:100vh;
    top:0;
    z-index: -9; 
}



/*-------------------------------------------------------------- Toppbild / Topbild ------------------------------------------------------------------------*/





.startsidatext {
    padding-top:3vh;
    padding-bottom:3vh;
    margin-top:75vh;
    position: relative;
    z-index: 15;
    background: #EEEEEE;
}


.topbild {
    margin-top: 40px;
    position: fixed;
    width:100%; 
    height:730px;
    top:0;
    z-index: -10; 
    background-size: cover;
    background-position: top center;
}






.startsidatext-content {
 max-width:910px;
}

.startsidatext h1 {
    font-size: 2.9em;
    text-align:center;
    padding-bottom:2vh;

}

.startsidatext_textdiv p {
    text-align:center;
    margin-top:0px;
    padding-top:0px;
    line-height: 150%;

}


.startsidatext_textdiv ul {
    margin-top:0px;
    padding-top:0px;
    line-height: 150%;
    font-family: "Roboto", sans-serif;
    font-size: 1.1em;
    font-weight: 300;
    line-height: 150%;
}


.sidtitle h1 {
    text-align:left;
    margin-top:2vh;
    padding-left:0.5vw;
    margin-bottom:0;
    padding-bottom:0;

}




.sida-text {
    margin-top:75vh;
    position: relative;
    z-index: 15;
    background: #eeeeee;
}


.sidbody {
    margin-top:0;
    padding-top:0;
    line-height: 150%;
}




.margin>* {
margin-top:0px;
}


/* ---------------------------------- Rubrikerna h1 h2 h3 h4 h5 h6 --------------------------- */

h1 {
    padding-top: 2vh;
    font-size: 2.8em;
    font-weight: 700;
    color: #2b5a8a;
    letter-spacing: 2px;
    
}

h2 {
    margin-top:0px;
    font-size: 2.0em;
    font-weight: 500;
    color: #2b5a8a;
    line-height:120%;
}

h3 {
    font-size: 1.8em;
    font-weight: 500;
    color: #2b5a8a;
}

h4 {
    font-size: 1.6em;
    font-weight: 500;
    color: #2b5a8a;
}

h5 {
    font-size: 1.3em;
    font-weight: 500;
    color: #2b5a8a;
}

h6 {
    font-size: 1.2em;
    font-weight: 500;
    color: #2b5a8a;
}

p  {
    margin-top:0px;
    padding-top:0px;
    line-height: 150%;
    font-family: "Roboto", sans-serif;
    font-size: 1.1em;
    font-weight: 300;
    line-height: 150%;
    
}

.sidbody ul  {
    line-height: 150%;
    font-family: "Roboto", sans-serif;
    font-size: 1.1em;
    font-weight: 300;
    line-height: 150%;
}


.sidbody p+ul  {
    margin-top:-15px;
    padding-top:-15px;

}


.sidbody li  {
    margin-bottom:10px;
}



p b, p strong {
    color:#2b5a8a;
    font-weight: 500;

}


/*------------------------------------------------------------------ Sida / Sidor ------------------------------------------------------------*/

.kontakt-sida {
    color: #666666;
    position: relative;
    margin-top: 75vh;
    padding-top:2vh;
    z-index: 15;
    background: rgba(250, 250, 250, 1.0);
}

.kontakt-sida h1 {
    margin-top: 1vh;
    font-size: 1.6em;
}




.bodytext ul {
    line-height: 180%;    
    font-size: 1.2em;
    font-weight: 300;
    margin-top:0;
}


.sida-top {
    margin-top: 15vh;
    margin-bottom: 5vh;
} 

.bildlista-bg {
background: #EEEEEE;
}

img {
    border: 0;
    max-width: 100%;
    height: auto;
}

.bildlista-lank {
    min-height: 60vh;
    width: 100%;
    position: relative;
    background-size: cover;
    background-position: center center;
}

a.bildlista-lank{
    display:block;
}


/*----------------------------------- Länkarna --------------------------------------*/

a {
    text-decoration: none;
}

a:active, a:hover {
    text-decoration: underline;

}

/*----------------------------------- Meny / Menu --------------------------------------*/

/* färg mellan-blå mellan-bla #0b3768 /// mörk-blå - mokr-bla #071a30 /// gul #f8ae1d */

nav {
    font-size:1.2em;
    color:#FFFFFF;
    float:right;
    font-family: 'AbhayaLibre', serif;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    line-height: 1.1em;

}

.menu li a
{
    color: #FFFFFF;
    text-decoration: none;
    border-style: solid;
    border-width: 1px;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-color: rgba(255, 255, 255, 0.0); /* osynlig / transparent för att ta samma utrymme som active*/
    padding-bottom: 3px;
    margin-bottom: 10px;
}

nav ul.menu .active > a, nav ul.menu .current a
{
    color: #FFFFFF;
    text-decoration: none;
    border-style: solid;
    border-width: 1px;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-color: #FFFFFF;
}

.menu li a:hover
{
    color: #FFFFFF;
    text-decoration: none;
    border-style: solid;
    border-width: 1px;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-color: #FFFFFF;

}

/* ------------------------------------------------ DropDownMenyn / Drop Down Menu -------------------------------------------- */

.menu .dropdown ul {
    top: 100%;
    left: 0px;
    background: rgba(43, 90, 138, 0.6);
    border-bottom:none;
    border-width: 0px;
}

.menu .dropdown li a {
    min-width: 100px;
    padding: 10px 10px 10px 10px;
    margin-left: 10px;
    margin-right: 10px;
    border-style: solid;
    border-width: 1px;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-color: rgba(255, 255, 255, 0.0); /* osynlig / transparent för att ta samma utrymme som active*/
}

.menu .dropdown li ul.menu .active a  {
    min-width: 100px;
    border-style: solid;
    border-width: 1px;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-color: rgba(255, 255, 255, 0.8);
}

.menu .dropdown li a:hover {
    min-width: 100px;
    border-style: solid;
    border-width: 1px;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-color: rgba(255, 255, 255, 0.8);
}

/*-------------------------------------------------------------- Länkboxar ------------------------------------------------------------------------*/

.startboxar {
    background: #2b5a8a;
    padding-top:1vh;
    padding-bottom:1vh;

}

.box_body h3 {
    padding-left:1vw;
    padding-right:1vw;
    color: rgb(86, 130, 157);
}


.box_body p {
    padding-left:1vw;
    padding-right:1vw;
    line-height: 150%;
}

.box_body strong {
    font-family: 'PT Serif', serif;
    color:#666666;
}

.box_redmore a {
    color: #FFFFFF;
    border-radius: 5px 0px 5px 0px;
    margin-left:1vw;
    padding:10px;
    background: rgb(86, 130, 157);
    -webkit-transition: background 0.8s ease;
    transition: background 0.8s ease;
}

.box_redmore a:hover {
    color: #FFFFFF;
    text-decoration:none;
    background: rgb(102, 102, 102);
    -webkit-transition: background 0.8s ease;
    transition: background 0.8s ease;
}

.lankboxbild {
    border-radius: 10px;
    min-height: 30vh;
    max-height: 30vh;
    position: relative;
    background-size: cover;
    background-position: center center;
}

.lankboxbild span{
    border-radius: 10px;
    font-weight: 300;
    font-size: 1.5em;
    letter-spacing: 3px;
    text-transform: uppercase;
    line-height: 1.5em;
    position:absolute; 
    height:100%;
    width:100%;
    color: #FFFFFF;
    background: rgba(64, 100, 113, 0.4);
    text-align: right;
    padding-right:0.5vw;
    padding-bottom:0.5vw;
}

a.lankboxbild{
    display:block;
}

.lankboxbild span{
    color: #FFFFFF;
    background: rgba(43, 90, 138, 0.65);
    -webkit-transition: color 0.8s ease, background 1.2s ease;
    transition: color 0.8s ease, background 1.2s ease;
}

.lankboxbild:hover span{
    color: rgba(64, 100, 113, 0.0);
    background: rgba(255, 255, 255, 0.0);
    text-decoration: none;
}

/* --------------------------------------------------------- Kontaktformulär / Kontaktbanner ----------------------------------------- */

.kontaktpersoner {
    background: rgba(250, 250, 250, 1.0);
    padding-top:1vh;
    padding-bottom:1vh;
}

.kontaktlankboxbild {
    min-height: 250px;
    max-height: 250px;
    position: relative;
    background-size: cover;
    background-position: center center;
}

a.kontaktlankboxbild{
    display:block;
}


.boltforms-row label {
    font-weight: 400;
    margin-bottom: 0;
}

.boltforms-row input {
    width: 100%;
    color: #606060;
}

.boltforms-row textarea {
    width: 100%;
    color: #606060;
    height: 10vh;
}

.boltforms-row button {
    display: block;
    width: 100%;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.2);
    margin: 0;
    border: none;
    border-radius: 2px;
    height: auto;
    padding: 10px 20px;
    font-weight: 400;
    font-size: 1em;
    line-height: 1.1em;
}

/*--------------------- Kontaktbanner-texten ----------------------*/
.kontaktbanner {
    background: #2b5a8a;
    color: #ffffff;
    padding-top: 30px;
    padding-bottom: 10px;
    text-align: center;
}

.kontaktbanner a {
    color: #ffffff;
}

.kontaktbanner h5 {
    color: #ffffff;
    font-size: 1.6em;
    margin-top: 18px;
    margin-bottom: 2vh;
}

.kontaktbanner h2 {
    padding: 0px 0 10px;
}

.kontaktbanner p {
    font-size: 1.1em;
    margin-bottom: 0px;
    line-height: 150%;
}

.kontaktsocialamedier .fa{
    margin-top: -10px;
    text-align: left;
    font-size: 1.8em;
    margin-left: 47%;
}

.kontaktinfoutrymme{
    margin-top: -10px;
    text-align: center;
}

.kontaktbanner .boltforms-row {
    float: left;
    width: 33.3%;
    padding-right: 10px;
}

.kontaktbanner .boltforms-row:nth-child(n+3) {
    width: 33%;
}

.kontaktbanner .boltforms-row:nth-child(n+4) {
    width: 100%;
}

button[type=submit]:hover, input[type=submit]:hover {
    background: rgba(0,0,0,0.4);
    color: #FFFFFF;
        -webkit-transition: color 1.5s ease, background 2.2s ease;
    transition: color 1.8s ease, background 2.2s ease;
}

/*------------------Give map a height and it's images no max-width----------*/
    .map-canvas {
    height: 36vh;
}

.map-canvas img {
    max-width: none;
}

/*--------------------------Make FB widget responsive------------------------*/
    .fb_iframe_widget,
    .fb_iframe_widget span,
    .fb_iframe_widget iframe[style],
    .twitter-timeline {
        width: 100% !important;
    }

@media (max-width: 1500px) {
   .bildspel_titel {
        margin-left: 20%;
        margin-right: 20%;
    }

}


/*Allt under M-nivå*/
@media (max-width: 1099px) {
    
 
    
    .underkonstruktion_bg span {
        font-size: 0.8em;
        padding-left: 5%;
        padding-right: 5%;
    }
    
    
        .underkonstruktion_bg p {
            margin-top: 5vh;
    }
    
    .underkonstruktion_logo {
    margin-top: 8%;
        margin-bottom: 0;
    height: 30vh;
    min-height: 30vh;
 }
    
} /* SLUT - Allt under M-nivå*/ 

/*Allt under S-nivå*/
@media (max-width: 799px) {
    
    h1 {

    font-size: 1.5em;
    
}
    
    
      .bildspel_titel {
        margin-left: 10%;
        margin-right: 10%;
    }

    .menu.show {
    background:rgba(43, 90, 138, 0.6);
    
    }
    
}  

/*Allt under XS-nivå*/
@media (max-width: 549px) {
    
    .underkonstruktion_bg span {
        font-size: 0.8em;
        padding-left: 1%;
        padding-right: 1%;
    line-height: 125%;
    }
    
    
    


.click2begin a:hover img {
    -webkit-transition: opacity 0.5s ease;
    transition: opacity 0.5s ease;
    opacity: 0.75;
}
    
    
    .click2begin a img {
        max-width: 220px;
        position:relative;
        padding-top: 150px;
        opacity: 0.9;
        z-index: -2;
        -webkit-transition: opacity 0.5s ease;
        transition: opacity 0.5s ease;
    }
    
    
.bildspel_titel {
    font-size: 1.1em;
    padding-top: 390px;

}

   
}

@media screen and (orientation: landscape) and (max-width: 800px) {

    .bildlista-lank {
        min-height: 100vh;
        width: 60%;
        position: relative;
        background-size: cover;
        background-position: top center;
    }

    a.bildlista-lank{
        display:block;
        margin-left: 20%;
    }

}

@media screen and (orientation: landscape) and (max-width: 800px) {

        .luftinnanfyllning:before {

            margin: 43vh 0 0;

        }

        .startsidatext {
            margin-top:46vh;


        }

    
    .lankboxbild {
    min-height: 150vh;
    max-height: 150vh;

}

    
    .click2begin a:hover img {
    -webkit-transition: opacity 0.5s ease;
    transition: opacity 0.5s ease;
    opacity: 0.75;
}
    
    
    .click2begin a img {
        max-width: 120px;
        position:relative;
        padding-top: 150px;
        opacity: 0.9;
        z-index: -2;
        -webkit-transition: opacity 0.5s ease;
        transition: opacity 0.5s ease;
    }
    
    
.bildspel_titel {
    font-size: 1.1em;
    padding-top: 300px;

}

    
    
    
    
}
